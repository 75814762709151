<template>
  <div class="layout flex">
    <Nav />
    <Main />
  </div>
</template>

<script>
import Nav from './components/nav'
import Main from './components/main'
export default {
  components: {
    Nav,
    Main
  }
}
</script>

<style>

</style>