import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Layout from "../views/Layout";
Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login/index.vue"),
    hidden: true,
    meta: {
      name: "登陆",
      icon: "icon-dili",
    },
  },
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home/"),
    meta: { requireAuth: true }, // true为这个页面需要登陆权限
    redirect: "index",
    meta: {
      meta: { requireAuth: true }, // true为这个页面需要登陆权限
      name: "主页",
      icon: "el-icon-house",
    },
    component: Layout,
    children: [
      {
        path: "/index",
        name: "index",
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/Home/"),
        meta: { requireAuth: true }, // true为这个页面需要登陆权限
      },
    ],
  },

  {
    path: "/artlist",
    name: "AltList",
    redirect: "artlist",
    meta: {
      name: "文章",
      icon: "el-icon-reading",
    },
    component: Layout,
    children: [
      {
        path: "/artlist",
        name: "artlist",
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/ArtList/"),
        meta: { requireAuth: true }, // true为这个页面需要登陆权限
      },
    ],
  },

  {
    path: "/addart",
    name: "AddArt",
    redirect: "addart",
    meta: {
      num: 8,
      name: "发布",
      icon: "el-icon-position",
    },
    component: Layout,
    children: [
      {
        path: "/addart",
        name: "addart",
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/ArtEdit/"),
        meta: { requireAuth: true }, // true为这个页面需要登陆权限
      },
    ],
  },
  {
    path: "/addart/:id",
    name: "AddArt",
    hidden: true,
    component: Layout,
    children: [
      {
        path: "/addart/:id",
        name: "addart",
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/ArtEdit/"),
        meta: { requireAuth: true }, // true为这个页面需要登陆权限
      },
    ],
  },
  {
    path: "/comment",
    name: "Comment",
    redirect: "comment",
    meta: {
      name: "留言",
      icon: "el-icon-chat-line-round",
    },
    component: Layout,
    children: [
      {
        path: "/comment",
        name: "comment",
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/Comment/"),
        meta: { requireAuth: true }, // true为这个页面需要登陆权限
      },
    ],
  },
  {
    path: "/aboutme",
    name: "AboutMe",
    redirect: "aboutme",
    meta: {
      num: 8,
      name: "关于",
      icon: "el-icon-user",
    },
    component: Layout,
    children: [
      {
        path: "/aboutme",
        name: "Aboutme",
        // component: () => import(/* webpackChunkName: "home" */ "../views/AboutMe/"),
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/AboutMe/index.vue"),
        meta: { requireAuth: true }, // true为这个页面需要登陆权限
      },
    ],
  },
  {
    path: "/message",
    name: "Message",
    redirect: "message",

    meta: {
      name: "说说",
      icon: "el-icon-edit",
    },
    component: Layout,
    children: [
      {
        path: "/message",
        name: "Message",
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/Message/meslist.vue"),
        meta: { requireAuth: true }, // true为这个页面需要登陆权限
      },
    ],
  },

  {
    path: "/like",
    name: "Like",
    redirect: "like",

    meta: {
      name: "友链",
      icon: "el-icon-link",
    },
    component: Layout,
    children: [
      {
        path: "/like",
        name: "Like",
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/Like/index.vue"),
        meta: { requireAuth: true }, // true为这个页面需要登陆权限
      },
    ],
  },

  {
    path: "/addlink/:id",
    name: "AddLinkEdit",
    hidden: true,
    component: Layout,
    children: [
      {
        path: "/addlink/:id",
        name: "addlinkedit",
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/Like/addlink.vue"),
        meta: { requireAuth: true }, // true为这个页面需要登陆权限
      },
    ],
  },

  {
    path: "/webset",
    name: "WebSet",
    redirect: "webset",

    meta: {
      name: "设置",
      icon: "el-icon-setting",
    },
    component: Layout,
    children: [
      {
        path: "/webset",
        name: "Webset",
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/WebSet/index.vue"),
        meta: { requireAuth: true }, // true为这个页面需要登陆权限
      },
    ],
  },
  {
    path: "/addmess",
    name: "AddMess",
    redirect: "addmess",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/addmess",
        name: "AddMess",
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/Message/addmes.vue"),
        meta: { requireAuth: true }, // true为这个页面需要登陆权限
      },
    ],
  },
  {
    path: "/addmess/:id",
    name: "AddMessEdit",
    hidden: true,
    component: Layout,
    children: [
      {
        path: "/addmess/:id",
        name: "addmessdit",
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/Message/addmes.vue"),
        meta: { requireAuth: true }, // true为这个页面需要登陆权限
      },
    ],
  },
  {
    path: "/addlink",
    name: "AddLink",
    redirect: "addlink",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/addlink",
        name: "AddLink",
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/Like/addlink.vue"),
        meta: { requireAuth: true }, // true为这个页面需要登陆权限
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

export default router;
